@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

.Home {
  font-family: 'Roboto', sans-serif;
  color: rgb(9, 43, 155)
}

.Home a {
  text-decoration: none;
}

.Home a:visited {
  color: white;
}

.Home .img-fluid {
  box-shadow: 0px 3px 3px 0px #adb5bd;
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
/* rtl:end:ignore */

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  /* rtl:remove */
  letter-spacing: -0.05rem;
}

.featurette-image {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.featurette-image > a > img {
  height: 475px;
}
/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

.Services {
  font-family: 'Roboto', sans-serif;
  color: rgb(9, 43, 155)
}

.Services a {
  text-decoration: none;
}

.Services a:visited {
  color: white;
}

.Services .img-fluid {
  box-shadow: 0px 3px 3px 0px #adb5bd;
}

/* Featurettes ------------------------- */

.Services .featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.Services .featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.05rem;
}

.Services .featurette-image {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height:  500px;
}

.Services .featurette-image > img {
  width: 100%;
}

.App textarea {
  resize: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App .pageBody {
  min-height: calc(100vh - 126px);
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

