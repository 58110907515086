/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.Services {
  font-family: 'Roboto', sans-serif;
  color: rgb(9, 43, 155)
}

.Services a {
  text-decoration: none;
}

.Services a:visited {
  color: white;
}

.Services .img-fluid {
  box-shadow: 0px 3px 3px 0px #adb5bd;
}

/* Featurettes ------------------------- */

.Services .featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.Services .featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.05rem;
}

.Services .featurette-image {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height:  500px;
}

.Services .featurette-image > img {
  width: 100%;
}
